import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/devices';

export default {
  getDevices(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getDeviceComplianceRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/compliance${queryString}`);
  },
  getDeviceDataHistoryRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/data${queryString}`);
  },
  getDevice(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  stats() {
    return axios.get(`${baseUrl}/stats`);
  },
  rebootDevice(uid) {
    return axios.put(`${baseUrl}/${uid}/reboot`);
  },
  lockDevice(uid) {
    return axios.put(`${baseUrl}/${uid}/lock`);
  },
  deleteDevice(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  disableDevice(uid, notes) {
    return axios.post(`${baseUrl}/${uid}/disable`, {'notes': notes});
  },
  enableDevice(uid, confirmed) {
    return axios.post(`${baseUrl}/${uid}/enable`, {'device_in_hands': confirmed});
  },

  // Super admin only endpoint
  isolateDevice(uid) {
    return axios.post(`/admin/devices/${uid}/isolate`);
  },
  getQrCodeEnrollment() {
    return axios.get(`${baseUrl}/enrollment`);
  },

  // Attach to owner
  attachOwner(deviceUid, ownerUid) {
    return axios.post(`${baseUrl}/${deviceUid}/assignment`, {'owner_uid': ownerUid});
  },

  detachOwner(deviceUid) {
    return axios.delete(`${baseUrl}/${deviceUid}/assignment`);
  },

  getTotp(timestamp) {
    return axios.post(`${baseUrl}/totp`, { timestamp });
  },
};
